import React from 'react'
import Page from '../../components/Page'
import SEO from '../../components/seo'
import ModuleMenu from '../../components/Modules/ModuleMenu'
import {TEMPERATURE_MODULE_DATA} from '../../components/Modules/module-data'
import ModuleContent from '../../components/Modules/ModuleContent'

const TemperatureModulePage = () => (
  <Page>
    <SEO
      title="Temperature Module GEN2 | Opentrons OT-2 Modular Hardware"
      description="The Opentrons Temperature Module sits securely on the deck of the OT-2 and holds plated reagents at temperatures between 4°C and 95°C."
    />
    <ModuleMenu currentUrl={TEMPERATURE_MODULE_DATA.basic.url} />
    <ModuleContent module={TEMPERATURE_MODULE_DATA} />
  </Page>
)

export default TemperatureModulePage
